// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import 'tailwindcss/base' layer(tailwindcss);
@import 'tailwindcss/components' layer(tailwindcss);
@import 'tailwindcss/utilities' layer(tailwindcss);

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';

@import './styles/variables.scss';
@import './styles/form.scss';
@import './styles/ck-editor.scss';

html,
body {
	height: 100%;
	background: #f5f5f5;
}
body {
	margin: 0;
	font-family: var(--font-family);
}

// .mat-typography {
//   font-family: var(--font-family);
// }

.file-input {
	display: none;
	opacity: 0;
	position: absolute;
	left: -99999px;
	top: -99999px;
	visibility: hidden;
}
.add-image {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 8px;
	cursor: pointer;
}

.image-container {
	margin-right: 16px;
	display: flex;
	flex-wrap: wrap;
	.file-input {
		display: none;
		opacity: 0;
		position: absolute;
		left: -99999px;
		top: -99999px;
		visibility: hidden;
	}
	.add-image {
		width: 100px;
		height: 100px;
		object-fit: cover;
		border-radius: 8px;
		opacity: 0.2;
		cursor: pointer;
	}
	.selected-image-container {
		position: relative;
		cursor: pointer;
		img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			margin-right: 16px;
			border-radius: 8px;
			margin-bottom: 8px;
		}

		.remove-button {
			position: absolute;
			top: -10px;
			right: 2px;
			margin: 0;
			padding: 0;
			height: unset;
			width: unset;
			background: white;
		}
	}
}

.ck-editor__editable_inline {
	max-height: 200px;
}
.ck-editor__editable {
	max-height: 200px;
	overflow-y: auto;
}

.error {
	@apply text-sm font-semibold text-red-600;
}

app-forum {
	.p-datatable-table th,
	.p-datatable-table tr:nth-child(even) {
		@apply bg-[#4da31414];
	}
}

.p-datatable .p-datatable-thead > tr > th {
	@apply text-[13px] font-semibold uppercase p-[0.3rem] border-x-[3px] border-x-[#fff];
}

.p-datatable-table tr {
	@apply cursor-pointer;
}

.p-datatable td {
	@apply text-sm p-[0.3rem] border-x-[3px] border-x-[#fff];
}

// .input-style {
// 	@apply border-2 border-primary-light rounded-md placeholder:italic focus:outline-none focus-visible:border-primary-dark text-sm w-full;
// }

.input-style {
	@apply border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark text-sm outline-none shadow-none;
}

.p-dropdown {
	@apply w-full;

	// .p-dropdown-label {
	// 	@apply pt-0 pb-0 pl-0;
	// }
}

.post-button {
	@apply flex items-center gap-1 p-1 rounded text-sm hover:bg-[#4da31410] transition-all;
}

.edit-button {
	@apply flex items-center gap-1 p-1 rounded text-sm transition-all bg-[#0080009c] text-white;
}

.delete-button {
	@apply flex items-center gap-1 p-1 rounded text-sm transition-all bg-[#ff4040e5] text-white;
}

.detail-fields {
	@apply flex gap-2 items-center;
}

.material-symbols-rounded-filled {
	font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

.forum-tag {
	@apply rounded-full px-3 py-2 flex items-center justify-center text-sm cursor-pointer font-medium;
}

app-add-topic {
	.p-chips-multiple-container {
		@apply w-full;

		&:focus-visible {
			outline: none !important;
			box-shadow: none !important;
		}
	}

	.p-chips-input-token {
		@apply p-0;
	}
}
